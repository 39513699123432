import * as React from "react";
// @ts-ignore
import {Col, Container, Form, Row, Button} from "react-bootstrap";

import * as style from './Header.module.scss';
import * as navStyle from '../../../../components/Navigation/Navigation.module.scss';
import * as contentStyle from '../../../../components/Content/Content.module.scss';
import {formButton, formInput, formLabel} from "../../../login/index.module.scss";
import * as mainStyle from '../../index.module.scss';

import {faArrowRight} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StaticImage} from "gatsby-plugin-image";
import {Helmet} from "react-helmet";
import {FC, useState} from "react";
import {Client} from "../../../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";

const mapState = ({member, auth}) => ({
    member,
    auth
})
const connector = connect(mapState, null);
type PropsFromRedux = ConnectedProps<typeof connector>


type HeaderProps = PropsFromRedux & {
    title: string,
    description: string,
    mediaPosLeft: string,
    image: string,
    topInfoBoxHeadline: string,
    topInfoBoxDescription: string,
    botInfoBoxHeadline: string,
    botInfoBoxDescription: string,
    botInfoBoxValue: string,
}


const Header: FC<HeaderProps> = (props: HeaderProps) => {

    const [isChecked, setIsChecked] = useState(false);

    return <>
        <Row className={mainStyle.box}>
            <Col lg={6} className={props.mediaPosLeft ? `order-lg-last ` + style.paddingLeft : style.paddingRight}>
                <div className={contentStyle.contentElement + " d-flex align-items-center flex-column"}>
                    <h1 className={style.headline}>{props.title}</h1>
                    <div className={style.normalHeader}>
                        <div className={style.description}>{props.description}</div>
                        <div className={style.highlightText}>
                            Quickstart-Guide per E-Mail erhalten
                        </div>
                        <div className={style.input}>
                            <div>
                                <form className="js-cm-form" id="subForm"
                                      action="https://www.createsend.com/t/subscribeerror?description=" method="post"
                                      data-id="30FEA77E7D0A9B8D7616376B90063231E6A7F56442F667257E1C1F992C9192031076D1357E3848BF9A56541D1D0B56525ECE7EF32A39A58586BD8979DFAFD22E">
                                    <div className={'mb-5'}>
                                        <Form.Group controlId="formBasicEmail" className={" " + style.formGroup}>

                                            <Form.Control autoComplete="Email"
                                                          aria-label="E-Mail-Adresse"
                                                          className={"js-cm-email-input qa-input-email " + style.formInput}
                                                          id="fieldEmail" maxLength="200"
                                                          name="cm-jlktkll-jlktkll"
                                                          placeholder={"Deine E-Mail Adresse"}
                                                          required="" type="email"/> <Button className={style.formBTN}
                                                                                             onClick={() => {

                                                                                                 if (props.member.MemberID) {
                                                                                                     Client.Auth.authGetNoID(props.auth.Key, 'Member/' + props.member.MemberID + '/gtagData').then((res) => {
                                                                                                         // @ts-ignore
                                                                                                         if (window.dataLayer) {
                                                                                                             let dataLayerPush = {
                                                                                                                 'cicData': {
                                                                                                                     'cic_member_mail': res.data.MemberMail ?? 'user not logged in',
                                                                                                                     'cic_country': res.data.Country ?? 'NOCOUNTRY',
                                                                                                                     'cic_plz': res.data.plz ?? 'NOPLZ',
                                                                                                                     'cic_location': res.data.City ?? 'NOCITY',
                                                                                                                 },
                                                                                                                 'event': 'fs.landingpage_quickstart_guide'
                                                                                                             };

                                                                                                             // @ts-ignore
                                                                                                             window.dataLayer.push(dataLayerPush);
                                                                                                         }
                                                                                                     })
                                                                                                 } else {
                                                                                                     if (window.dataLayer) {
                                                                                                         let dataLayerPush = {
                                                                                                             'cicData': {
                                                                                                                 'cic_member_mail': 'user not logged in',
                                                                                                                 'cic_country': 'NOCOUNTRY',
                                                                                                                 'cic_plz': 'NOPLZ',
                                                                                                                 'cic_location': 'NOCITY',
                                                                                                             },
                                                                                                             'event': 'fs.landingpage_quickstart_guide'
                                                                                                         };

                                                                                                         // @ts-ignore
                                                                                                         window.dataLayer.push(dataLayerPush);
                                                                                                     }
                                                                                                 }}
                                                                                             } type="submit"
                                                                                             disabled={!isChecked}>
                                            Senden
                                        </Button> </Form.Group>

                                        <div>
                                            <div>
                                                <div className={'d-inline'}>
                                                    <label
                                                        htmlFor="cm-privacy-consent"> <input aria-required=""
                                                                                             id="cm-privacy-consent"
                                                                                             name="cm-privacy-consent"
                                                                                             required=""
                                                                                             className={'me-2 mt-4'}
                                                                                             onChange={(e) => {
                                                                                                 setIsChecked(e.target.checked)
                                                                                             }}
                                                                                             type="checkbox"/>Ich habe
                                                        die Datenschutzerklärung
                                                        gelesen und stimme der Verarbeitung meiner E-Mail-Adresse
                                                        zu.</label></div>
                                                <input id="cm-privacy-consent-hidden" name="cm-privacy-consent-hidden"
                                                       type="hidden" value="true"/>
                                            </div>
                                        </div>
                                    </div>
                                    <span onClick={() => {
                                        if (props.member.MemberID) {
                                            Client.Auth.authGetNoID(props.auth.Key, 'Member/' + props.member.MemberID + '/gtagData').then((res) => {
                                                // @ts-ignore
                                                if (window.dataLayer) {
                                                    let dataLayerPush = {
                                                        'cicData': {
                                                            'cic_member_mail': res.data.MemberMail ?? 'user not logged in',
                                                            'cic_country': res.data.Country ?? 'NOCOUNTRY',
                                                            'cic_plz': res.data.plz ?? 'NOPLZ',
                                                            'cic_location': res.data.City ?? 'NOCITY',
                                                        },
                                                        'event': 'fs.landingpage_startnow'
                                                    };

                                                    // @ts-ignore
                                                    window.dataLayer.push(dataLayerPush);

                                                    window.location.href = '/register/vendor';
                                                }
                                            })
                                        } else {
                                            if (window.dataLayer) {
                                                let dataLayerPush = {
                                                    'cicData': {
                                                        'cic_member_mail': 'user not logged in',
                                                        'cic_country': 'NOCOUNTRY',
                                                        'cic_plz': 'NOPLZ',
                                                        'cic_location': 'NOCITY',
                                                    },
                                                    'event': 'fs.landingpage_startnow'
                                                };

                                                // @ts-ignore
                                                window.dataLayer.push(dataLayerPush);

                                                window.location.href = '/register/vendor';
                                            }
                                        }
                                    }
                                    } className={style.formBTN + " text-white cursor-pointer"}>
                                        oder gleich starten <FontAwesomeIcon icon={faArrowRight}/>
                                    </span>
                                </form>
                            </div>
                            <Helmet>
                                <script type="text/javascript"
                                        src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"></script>
                            </Helmet></div>
                    </div>
                </div>
            </Col>

            <Col lg={6}
                 className={props.mediaPosLeft ? style.paddingRight : style.paddingLeft + " d-flex align-items-center justify-content-center"}>
                <div className={"position-relative " + style.imageContainer}>
                    <img src={props.image} className={style.image}/>
                    <div className={style.topInfoBox + " " + style.infoBox}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
                            <g transform="translate(-1044 -401)">
                                <circle cx="28" cy="28" r="28" transform="translate(1044 401)" fill="#bee000"/>
                                <g transform="translate(1052 402.77)">
                                    <path
                                        d="M29.817,33.136H24.187a.6.6,0,0,1,0-1.195h5.629a.6.6,0,0,1,0,1.195Zm4.6-6.054a.608.608,0,0,1-.542-.345,7.394,7.394,0,0,0-1.491-2.113,7.615,7.615,0,0,0-12.263,2.119.6.6,0,1,1-1.078-.511A8.806,8.806,0,0,1,33.229,23.78a8.667,8.667,0,0,1,1.725,2.451.594.594,0,0,1-.289.795.556.556,0,0,1-.246.055ZM27,20.257a5.014,5.014,0,1,1,5.014-5.014A5.018,5.018,0,0,1,27,20.257Zm0-8.826a3.819,3.819,0,1,0,3.819,3.819A3.822,3.822,0,0,0,27,11.431Z"
                                        transform="translate(-7.293)" fill="#fff"/>
                                    <path
                                        d="M8.808,26.607a5.014,5.014,0,1,1,5.014-5.014A5.018,5.018,0,0,1,8.808,26.607Zm0-8.832a3.819,3.819,0,1,0,3.819,3.819A3.822,3.822,0,0,0,8.808,17.775Zm8.2,21.7H.6a.594.594,0,0,1-.6-.6V36.351a8.808,8.808,0,0,1,17.615,0v2.531a.6.6,0,0,1-.6.6ZM1.195,38.285H16.42V36.351a7.613,7.613,0,0,0-15.225,0v1.934ZM30.611,26.607a5.014,5.014,0,1,1,5.014-5.014A5.018,5.018,0,0,1,30.611,26.607Zm0-8.832a3.819,3.819,0,1,0,3.819,3.819A3.822,3.822,0,0,0,30.611,17.775Zm8.21,21.7H22.407a.594.594,0,0,1-.6-.6V36.351a8.808,8.808,0,0,1,17.615,0v2.531a.6.6,0,0,1-.6.6ZM23,38.285H38.23V36.351a7.613,7.613,0,0,0-15.225,0Z"
                                        transform="translate(0 -2.439)" fill="#fff"/>
                                </g>
                            </g>
                        </svg>
                        <div className={style.infoBoxHeadline + " " + style.infoBoxHeadlineTop}>
                            {props.topInfoBoxHeadline}
                        </div>
                        <div className={style.infoBoxDescription}>
                            {props.topInfoBoxDescription}
                        </div>
                    </div>
                    <div className={style.botInfoBox + " align-items-start " + style.infoBox}>
                        <Row className={"d-flex justify-content-center align-items-center " + style.firstRow}>
                            <Col lg={2} xs={4} className={style.boxContainerBot}>

                                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
                                    <g transform="translate(-1372 -796)">
                                        <circle cx="28" cy="28" r="28" transform="translate(1372 796)" fill="#004036"/>
                                        <g transform="translate(1376 800.21)">
                                            <path
                                                d="M14.355,13.017H12.349A5.349,5.349,0,0,1,7,7.669.669.669,0,0,1,7.669,7H9.674a5.349,5.349,0,0,1,5.349,5.349A.669.669,0,0,1,14.355,13.017ZM8.391,8.337a4.012,4.012,0,0,0,3.958,3.343h1.284A4.012,4.012,0,0,0,9.674,8.337Z"
                                                transform="translate(0 0)" fill="#fff"/>
                                            <path
                                                d="M19.674,16.017H17.669A.669.669,0,0,1,17,15.349,5.349,5.349,0,0,1,22.349,10h2.006a.669.669,0,0,1,.669.669A5.349,5.349,0,0,1,19.674,16.017ZM18.391,14.68h1.284a4.012,4.012,0,0,0,3.958-3.343H22.349A4.012,4.012,0,0,0,18.391,14.68Z"
                                                transform="translate(-3.314 -0.994)" fill="#fff"/>
                                            <path
                                                d="M17.669,31.052A.669.669,0,0,1,17,30.384V13.669a.669.669,0,1,1,1.337,0V30.384A.669.669,0,0,1,17.669,31.052Z"
                                                transform="translate(-3.314 -1.988)" fill="#fff"/>
                                            <path
                                                d="M15.35,50.512a5.349,5.349,0,0,1-3.784-9.126l3.31-3.343a.669.669,0,0,1,.949,0l3.31,3.343a5.349,5.349,0,0,1,0,7.562h0A5.349,5.349,0,0,1,15.35,50.512Zm0-11.019-2.835,2.835a4.012,4.012,0,1,0,5.67,0Zm3.343,8.979Z"
                                                transform="translate(-0.995 -10.222)" fill="#fff"/>
                                            <path
                                                d="M17.34,49.349H15.669a.669.669,0,0,1,0-1.337H17.34a.334.334,0,1,0,0-.669h-.669a1.672,1.672,0,1,1,0-3.343h1.672a.669.669,0,1,1,0,1.337H16.672a.334.334,0,1,0,0,.669h.669a1.672,1.672,0,1,1,0,3.343Z"
                                                transform="translate(-2.651 -12.262)" fill="#fff"/>
                                            <path
                                                d="M17.669,35.412A.669.669,0,0,1,17,34.743v-.669a.669.669,0,0,1,1.337,0v.669A.669.669,0,0,1,17.669,35.412Zm0,4.68A.669.669,0,0,1,17,39.424v-.669a.669.669,0,1,1,1.337,0v.669A.669.669,0,0,1,17.669,40.092Zm2.006-10.7a.669.669,0,0,1-.475-1.143l8.023-8.023a.669.669,0,0,1,.949,0l3.537,3.524,8.872-9.521a.669.669,0,0,1,.976.909L32.2,25.169a.669.669,0,0,1-.488.214.7.7,0,0,1-.488-.194L27.7,21.645,20.149,29.2A.669.669,0,0,1,19.674,29.395Z"
                                                transform="translate(-3.314 -2.336)" fill="#fff"/>
                                            <path
                                                d="M44.052,20.017a.669.669,0,0,1-.669-.669V15.337H39.372a.669.669,0,1,1,0-1.337h4.68a.669.669,0,0,1,.669.669v4.68A.669.669,0,0,1,44.052,20.017Zm2.006,20.727H26.669a.669.669,0,0,1,0-1.337h19.39a.669.669,0,1,1,0,1.337Z"
                                                transform="translate(-6.296 -2.32)" fill="#fff"/>
                                            <path
                                                d="M41.366,44.715a.669.669,0,0,1-.669-.669V32.781a.669.669,0,1,1,1.337,0V44.047A.669.669,0,0,1,41.366,44.715Zm0-14.041a.669.669,0,0,1-.669-.669V28.669a.669.669,0,1,1,1.337,0v1.337A.669.669,0,0,1,41.366,30.674ZM36.017,45.384a.669.669,0,0,1-.669-.669V38.029a.669.669,0,0,1,1.337,0v6.686A.669.669,0,0,1,36.017,45.384Zm0-9.361a.669.669,0,0,1-.669-.669V34.017a.669.669,0,1,1,1.337,0v1.337A.669.669,0,0,1,36.017,36.023Zm-5.349,9.361A.669.669,0,0,1,30,44.715V41.372a.669.669,0,1,1,1.337,0v3.343A.669.669,0,0,1,30.669,45.384Zm0-6.017A.669.669,0,0,1,30,38.7V37.361a.669.669,0,0,1,1.337,0V38.7A.669.669,0,0,1,30.669,39.366Z"
                                                transform="translate(-7.622 -6.959)" fill="#fff"/>
                                        </g>
                                    </g>
                                </svg>
                            </Col>
                            <Col lg={10} xs={8} className={style.botBox}>
                                <div className={style.infoBoxHeadline}>
                                    {props.botInfoBoxHeadline}
                                </div>
                                <div className={style.infoBoxValue}>
                                    {props.botInfoBoxValue}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className={style.infoBoxBotDescription}>
                                {props.botInfoBoxDescription}
                            </div>
                        </Row>
                    </div>
                </div>
                <div className={style.mobileHeader}>
                    <div className={style.description}>{props.description}</div>
                    <div className={style.highlightText}>
                        Quickstart-Guide per E-Mail erhalten
                    </div>
                    <div className={style.input}>
                        <div>
                            <form className="js-cm-form d-flex flex-column justify-content-center align-items-center" id="subForm"
                                  action="https://www.createsend.com/t/subscribeerror?description=" method="post"
                                  data-id="30FEA77E7D0A9B8D7616376B90063231E6A7F56442F667257E1C1F992C9192031076D1357E3848BF9A56541D1D0B56525ECE7EF32A39A58586BD8979DFAFD22E">
                                <div>
                                    <Form.Group controlId="formBasicEmail" className={" " + style.formGroup}>

                                        <Form.Control autoComplete="Email"
                                                      aria-label="E-Mail-Adresse"
                                                      className={"js-cm-email-input qa-input-email " + style.formInput}
                                                      id="fieldEmail" maxLength="200"
                                                      name="cm-jlktkll-jlktkll"
                                                      placeholder={"Deine E-Mail Adresse"}
                                                      required="" type="email"/> <Button className={style.formBTN}
                                                                                         onClick={() => {

                                                                                             if (props.member.MemberID) {
                                                                                                 Client.Auth.authGetNoID(props.auth.Key, 'Member/' + props.member.MemberID + '/gtagData').then((res) => {
                                                                                                     // @ts-ignore
                                                                                                     if (window.dataLayer) {
                                                                                                         let dataLayerPush = {
                                                                                                             'cicData': {
                                                                                                                 'cic_member_mail': res.data.MemberMail ?? 'user not logged in',
                                                                                                                 'cic_country': res.data.Country ?? 'NOCOUNTRY',
                                                                                                                 'cic_plz': res.data.plz ?? 'NOPLZ',
                                                                                                                 'cic_location': res.data.City ?? 'NOCITY',
                                                                                                             },
                                                                                                             'event': 'fs.landingpage_startnow'
                                                                                                         };

                                                                                                         // @ts-ignore
                                                                                                         window.dataLayer.push(dataLayerPush);
                                                                                                     }
                                                                                                 })
                                                                                             } else {
                                                                                                 if (window.dataLayer) {
                                                                                                     let dataLayerPush = {
                                                                                                         'cicData': {
                                                                                                             'cic_member_mail': 'user not logged in',
                                                                                                             'cic_country': 'NOCOUNTRY',
                                                                                                             'cic_plz': 'NOPLZ',
                                                                                                             'cic_location': 'NOCITY',
                                                                                                         },
                                                                                                         'event': 'fs.landingpage_startnow'
                                                                                                     };

                                                                                                     // @ts-ignore
                                                                                                     window.dataLayer.push(dataLayerPush);
                                                                                                 }
                                                                                             }}}
                                                                                         type="submit"
                                                                                         disabled={!isChecked}>
                                        Senden
                                    </Button> </Form.Group>

                                    <div>
                                        <div className={'mt-3 mb-3'}>
                                            <div className={'d-flex align-items-center'}>
                                                <input aria-required="" id="cm-privacy-consent"
                                                       name="cm-privacy-consent" required=""
                                                       className={style.inputCheckBox}
                                                       onChange={(e) => {
                                                           setIsChecked(e.target.checked)
                                                       }}
                                                       type="checkbox"/><label
                                                htmlFor="cm-privacy-consent">Ich habe die Datenschutzerklärung
                                                gelesen und stimme der Verarbeitung meiner E-Mail-Adresse
                                                zu.</label></div>
                                            <input id="cm-privacy-consent-hidden" name="cm-privacy-consent-hidden"
                                                   type="hidden" value="true"/>
                                        </div>
                                    </div>
                                </div>
                                <span className={style.formBTN + " my-4"} onClick={() => {
                                    if (props.member.MemberID) {
                                        Client.Auth.authGetNoID(props.auth.Key, 'Member/' + props.member.MemberID + '/gtagData').then((res) => {
                                            // @ts-ignore
                                            if (window.dataLayer) {
                                                let dataLayerPush = {
                                                    'cicData': {
                                                        'cic_member_mail': res.data.MemberMail ?? 'user not logged in',
                                                        'cic_country': res.data.Country ?? 'NOCOUNTRY',
                                                        'cic_plz': res.data.plz ?? 'NOPLZ',
                                                        'cic_location': res.data.City ?? 'NOCITY',
                                                    },
                                                    'event': 'fs.landingpage_startnow'
                                                };

                                                // @ts-ignore
                                                window.dataLayer.push(dataLayerPush);

                                                window.location.href = '/register/vendor';
                                            }
                                        })
                                    } else {
                                        if (window.dataLayer) {
                                            let dataLayerPush = {
                                                'cicData': {
                                                    'cic_member_mail': 'user not logged in',
                                                    'cic_country': 'NOCOUNTRY',
                                                    'cic_plz': 'NOPLZ',
                                                    'cic_location': 'NOCITY',
                                                },
                                                'event': 'fs.landingpage_startnow'
                                            };

                                            // @ts-ignore
                                            window.dataLayer.push(dataLayerPush);

                                            window.location.href = '/register/vendor';
                                        }
                                    }
                                }
                                }>
                                    oder gleich starten <FontAwesomeIcon icon={faArrowRight}/>
                                </span>
                            </form>
                        </div>
                        <Helmet>
                            <script type="text/javascript"
                                    src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"></script>
                        </Helmet></div>
                </div>
            </Col>
        </Row>
    </>;
}

export default connector(Header);
