// extracted by mini-css-extract-plugin
export var botBox = "Header-module--botBox--3rD+D";
export var botInfoBox = "Header-module--botInfoBox--WVg5d";
export var boxContainerBot = "Header-module--boxContainerBot--Y1+ae";
export var description = "Header-module--description--krOn4";
export var firstRow = "Header-module--firstRow--2RYRe";
export var formBTN = "Header-module--formBTN--SMXIA";
export var formGroup = "Header-module--formGroup--kSw6W";
export var formInput = "Header-module--formInput--ZXeYe";
export var headline = "Header-module--headline--wuCOC";
export var highlightText = "Header-module--highlightText--ZaCIY";
export var image = "Header-module--image--mFyaz";
export var imageContainer = "Header-module--imageContainer--JCt-+";
export var infoBox = "Header-module--infoBox--OMIVW";
export var infoBoxBotDescription = "Header-module--infoBoxBotDescription--tcQTP";
export var infoBoxDescription = "Header-module--infoBoxDescription--PANEm";
export var infoBoxHeadline = "Header-module--infoBoxHeadline--yaKgf";
export var infoBoxHeadlineTop = "Header-module--infoBoxHeadlineTop--iHWrc";
export var infoBoxIMG = "Header-module--infoBoxIMG--pvKCV";
export var infoBoxValue = "Header-module--infoBoxValue--XDW3M";
export var input = "Header-module--input--TnbT5";
export var inputCheckBox = "Header-module--inputCheckBox--7Y-Dx";
export var mobileHeader = "Header-module--mobileHeader--rHwUC";
export var normalHeader = "Header-module--normalHeader--2ODaT";
export var paddingLeft = "Header-module--paddingLeft--yZLsT";
export var paddingRight = "Header-module--paddingRight--E8efN";
export var topInfoBox = "Header-module--topInfoBox--SSg7x";